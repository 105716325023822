// src/core.tsx
import React2 from "react";
import { createPortal as createPortal2, flushSync } from "react-dom";

// src/root.tsx
import React, { useMemo, useSyncExternalStore } from "react";
import { createPortal } from "react-dom";
import { createRoot } from "react-dom/client";
var ExternalStore = class {
  constructor(_state) {
    this._state = _state;
  }
  _listeners = /* @__PURE__ */ new Set();
  subscribe = (listener) => {
    this._listeners.add(listener);
    return () => {
      this._listeners.delete(listener);
    };
  };
  getState() {
    return this._state;
  }
  setState(update) {
    if (typeof update === "function") {
      update = update(this._state);
    }
    Object.assign(this._state, update);
    this._listeners.forEach((l) => l());
  }
};
function useStoreSelector(store, selector) {
  return useSyncExternalStore(store.subscribe, () => selector(store.getState()));
}
var rcStore = new ExternalStore({ contexts: [], components: [] });
function nestedContexts(contexts) {
  return React.memo(function WrappedContext({ children }) {
    return contexts.reduceRight((child, Context) => /* @__PURE__ */ React.createElement(Context, null, child), children);
  });
}
function AnvilRoot() {
  const reactContexts = useStoreSelector(rcStore, (s) => s.contexts);
  const reactComponents = useStoreSelector(rcStore, (s) => s.components);
  const ContextProviders = useMemo(() => nestedContexts(reactContexts), [reactContexts]);
  const portalNodes = useMemo(
    () => reactComponents.map((c) => {
      if (!c._.portalElement)
        return null;
      return createPortal(c._.reactComponent(), c._.portalElement, c._.id.toString());
    }),
    [reactComponents]
  );
  return /* @__PURE__ */ React.createElement(ContextProviders, null, portalNodes);
}
var rootElement = document.createElement("div");
rootElement.style.display = "none";
rootElement.dataset.anvilReactRoot = "";
var root = createRoot(rootElement);
root.render(/* @__PURE__ */ React.createElement(AnvilRoot, null));
document.body.append(rootElement);

// src/core.tsx
var { _jsComponentApi } = window.anvil;
var {
  designerApi,
  notifyMounted,
  notifyUnmounted,
  notifyVisibilityChange,
  raiseAnvilEvent,
  triggerWriteBack,
  registerJsComponent,
  registerToolboxSection,
  propertyUtils,
  subscribeAnvilEvent,
  getClientConfig,
  getParent
} = _jsComponentApi;
var { openForm } = _jsComponentApi;
var HooksContext = React2.createContext(null);
var DropZoneContext = React2.createContext(null);
var useNotifyMounted = (c, isRoot = false) => {
  React2.useLayoutEffect(() => {
    notifyMounted(c, isRoot);
    return () => {
      const parent = getParent(c);
      if (isRoot || parent === null || isReactComponent(parent)) {
        notifyUnmounted(c, isRoot);
      }
    };
  }, []);
};
var portalElementCache = /* @__PURE__ */ new WeakMap();
var ElementWrapper = ({ el, c }) => {
  const ref = React2.useRef(null);
  React2.useLayoutEffect(() => {
    ref.current.appendChild(el);
    return () => {
      el.remove();
    };
  }, [el]);
  useNotifyMounted(c);
  return /* @__PURE__ */ React2.createElement("div", { "data-anvil-react-wrapper": "", ref }, portalElementCache.get(c)?.components.map(
    (c2) => c2._.portalElement && createPortal2(c2._.reactComponent(), c2._.portalElement, c2._.id.toString())
  ));
};
var errorStyle = {
  textAlign: "center",
  padding: "1rem",
  border: "1px dashed black",
  wordWrap: "break-word",
  color: "#a00",
  backgroundColor: "#faa"
};
function ErrorFallback({ self, children }) {
  React2.useEffect(() => {
    designerApi.inDesigner && designerApi.notifyDomNodeChanged(self);
  }, []);
  return /* @__PURE__ */ React2.createElement("div", { ref: self._.ref, style: errorStyle }, children);
}
var ErrorBoundary = class extends React2.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidMount() {
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
  }
  reset() {
    this.setState({ hasError: false, error: null });
  }
  componentDidCatch(error, info) {
  }
  render() {
    if (this.state.hasError) {
      const self = this.props.self;
      self._.forceRender = () => this.reset();
      const msg = `${self._.designName ?? self._.name ?? "This"} component experienced an error: ${this.state.error?.toString()}`;
      return /* @__PURE__ */ React2.createElement(ErrorFallback, { self }, msg);
    }
    return this.props.children;
  }
};
var DropZone = ({
  minChildIdx = void 0,
  maxChildIdx = void 0,
  childIdx = void 0,
  layoutProperties = void 0,
  ...props
}) => {
  const ctx = React2.useContext(DropZoneContext);
  if (!ctx.dropping)
    return null;
  if (minChildIdx === void 0 && maxChildIdx === void 0 && childIdx !== void 0) {
    minChildIdx = maxChildIdx = childIdx;
  } else if (childIdx !== void 0 && (minChildIdx !== void 0 || maxChildIdx !== void 0)) {
    console.warn("DropZones only accept either childIdx or minChildIdx,maxChildIdx. Not both.");
  }
  const ref = (element) => {
    if (element) {
      ctx.dropZones.push({
        element,
        expandable: true,
        dropInfo: { minChildIdx, maxChildIdx, layout_properties: layoutProperties }
      });
    }
  };
  return /* @__PURE__ */ React2.createElement("div", { "data-anvil-react-dropzone": "", ref, ...props });
};
var IS_REACT = Symbol();
function setRef(ref, value) {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}
var isReactComponent = (obj) => !!obj?.[IS_REACT];
var componentId = 0;
function setupReactComponent(self, spec) {
  const {
    name,
    component,
    properties,
    layoutProperties,
    events,
    container,
    autoDropZones = true,
    autoDropZoneProps
  } = spec;
  const getStringProp = (propName) => {
    const rv = properties?.find(({ name: name2 }) => propName === name2) ?? { name: propName, type: "string" };
    return rv;
  };
  const actions = {
    async raiseEvent(eventName, args = {}) {
      await raiseAnvilEvent(self, eventName, args);
    },
    async triggerWriteBack(property, value) {
      await triggerWriteBack(self, property, value);
    },
    setProperty(propName, value) {
      if (designerApi.inDesigner) {
        flushSync(() => {
          _.propertyInterface[propName] = value;
        });
        designerApi.updateComponentProperties(self, { [propName]: value }, {});
        designerApi.updateComponentSections(self);
      } else {
        _.propertyInterface[propName] = value;
      }
    }
  };
  const propertyState = Object.fromEntries(properties?.map(({ name: name2, defaultValue }) => [name2, defaultValue]) || []);
  const designerApiContext = {
    inDesigner: designerApi.inDesigner,
    updateProperties(updates) {
      flushSync(() => {
        for (const [name2, newValue] of Object.entries(updates || {})) {
          _.propertyInterface[name2] = newValue;
        }
      });
      designerApi.updateComponentProperties(self, updates, {});
      designerApi.updateComponentSections(self);
    },
    designName: null,
    startEditingForm(form) {
      designerApi.startEditingForm(self, form);
    },
    startInlineEditing(prop, element, options) {
      const property = properties?.find(({ name: name2 }) => name2 === prop);
      if (property) {
        designerApi.startInlineEditing(self, property, element, options);
      }
    }
  };
  const hooksContext = {
    useActions: () => actions,
    useDesignerApi: () => ({ ...designerApiContext, designName: designerApi.getDesignName(self) }),
    useDropping: () => _.dropping,
    useInteraction: (maybeInteraction) => {
      const id = React2.useId();
      if (maybeInteraction) {
        if ("sectionId" in maybeInteraction) {
          const { sectionId, ...interaction } = maybeInteraction;
          let sectionMap = _.sectionInteractions.get(sectionId);
          if (sectionMap === void 0) {
            sectionMap = /* @__PURE__ */ new Map();
            _.sectionInteractions.set(sectionId, sectionMap);
          }
          sectionMap.set(id, interaction);
        } else {
          _.interactions.set(id, maybeInteraction);
        }
      }
    },
    useInlineEditRef: (propName, otherRef, { onStart, onEnd } = {}) => {
      return (element) => {
        setRef(otherRef, element);
        if (element) {
          _.inlineEditInteractions.set(propName, {
            type: "whole_component",
            title: `Edit ${propName}`,
            icon: "edit",
            default: true,
            callbacks: {
              execute() {
                onStart?.();
                designerApi.startInlineEditing(self, getStringProp(propName), element, {
                  onFinished: onEnd
                });
              }
            }
          });
        }
      };
    },
    useInlineEditRegionRef: (propName, otherRef, { onStart, onEnd } = {}) => {
      return (element) => {
        setRef(otherRef, element);
        if (element) {
          _.inlineEditInteractions.set(propName, {
            type: "region",
            sensitivity: 2,
            bounds: element,
            callbacks: {
              execute() {
                onStart?.();
                designerApi.startInlineEditing(self, getStringProp(propName), element, {
                  onFinished: onEnd
                });
              }
            }
          });
        }
      };
    },
    useInlineEditSectionRef(sectionId, propName, otherRef, cbs) {
      return (element) => {
        setRef(otherRef, element);
        if (element) {
          addSectionInlineEditInteraction(sectionId, propName, element, cbs);
        }
      };
    },
    useSectionRef: (section, otherRef) => (element) => {
      setRef(otherRef, element);
      if (element) {
        _.sections.set(section.id, { ...section, element });
        designerApi.updateComponentSections(self, { [section.id]: { element } });
      }
    },
    useSectionRefs: (sections) => {
      const sectionRefs = {};
      for (const section of sections) {
        sectionRefs[section.id] = {
          ref: (element) => {
            if (element) {
              _.sections.set(section.id, { ...section, element });
              if (section.inlineEditRoot) {
                addSectionInlineEditInteraction(section.id, section.inlineEditRoot, element);
              }
            }
          },
          inlineEditRefs: Object.fromEntries(
            section.inlineEditProps?.map((propName) => [
              propName,
              (element) => {
                if (element) {
                  addSectionInlineEditInteraction(section.id, propName, element);
                }
              }
            ]) || []
          )
        };
      }
      return sectionRefs;
    },
    useComponentState: (initialState) => {
      if (designerApi.inDesigner) {
        const [_s, setS] = React2.useState(initialState);
        const id = `${_.nextDesignerStateId++}`;
        const state = designerApi.getDesignerState(self);
        let currentValue;
        if (state.has(id)) {
          currentValue = state.get(id);
        } else {
          state.set(id, initialState);
          currentValue = initialState;
        }
        return [
          currentValue,
          (newStateOrFn) => {
            const newState = typeof newStateOrFn === "function" ? newStateOrFn(state.get(id)) : newStateOrFn;
            state.set(id, newState);
            setS(newState);
          }
        ];
      } else {
        return React2.useState(initialState);
      }
    },
    useRegionInteractionRef: (execute, otherRef, { sensitivity = 0 } = {}) => {
      const localRef = React2.useRef(null);
      hooksContext.useInteraction({
        type: "region",
        bounds: localRef,
        callbacks: { execute },
        sensitivity
      });
      return (element) => {
        setRef(otherRef, element);
        setRef(localRef, element);
      };
    },
    useDesignerInteractionRef: (event, callback, otherRef, options = {}) => (element) => {
      setRef(otherRef, element);
      const { enabled = true } = options;
      if (element && enabled) {
        designerApi.registerInteraction(self, { event, callback, element });
      }
    },
    useVisibility: (visible) => {
      notifyVisibilityChange(self, visible);
    }
  };
  const WrappedComponent = React2.forwardRef((props, ref) => {
    _.interactions.clear();
    _.inlineEditInteractions.clear();
    _.sections.clear();
    _.sectionInlineEditInteractions.clear();
    _.sectionInteractions.clear();
    _.nextDesignerStateId = 0;
    return component(props, ref);
  });
  WrappedComponent.displayName = "WrappedComponent";
  const InnerComponent = ({ isRoot }) => {
    const _2 = _ReactComponentWrapper;
    _2.forceRender = React2.useReducer(() => ({}), {})[1];
    _2.dropZones = [];
    const dropZoneContext = {
      dropping: _2.dropping,
      dropZones: _2.dropZones
    };
    const children = [];
    const childKeys = /* @__PURE__ */ new WeakMap();
    const childrenWithLayoutProperties = [];
    if (autoDropZones) {
      children.push(
        /* @__PURE__ */ React2.createElement(DropZone, { key: "anvil-child-[-1]-dz", minChildIdx: 0, maxChildIdx: 0, ...autoDropZoneProps })
      );
    }
    const childrenWithoutDropZones = [];
    let i = 0;
    for (const c2 of _2.components) {
      let child;
      const key = `anvil-child-${_2.componentKeys.get(c2)}`;
      if (isReactComponent(c2)) {
        delete c2._.portalElement;
        child = /* @__PURE__ */ React2.createElement(React2.Fragment, { key }, c2._.reactComponent());
      } else {
        child = /* @__PURE__ */ React2.createElement(ElementWrapper, { key, el: c2._anvilDomElement, c: c2 });
      }
      children.push(child);
      childrenWithoutDropZones.push(child);
      childrenWithLayoutProperties.push({
        child,
        layoutProperties: _2.componentLayoutProps.get(c2),
        visible: _2.componentVisibility.get(c2),
        childIdx: i,
        key
      });
      childKeys.set(child, key);
      if (autoDropZones) {
        children.push(
          /* @__PURE__ */ React2.createElement(DropZone, { key: `${key}-dz`, minChildIdx: i + 1, maxChildIdx: i + 1, ...autoDropZoneProps })
        );
      }
      i++;
    }
    const props = {
      children,
      // TODO: This only passes non-undefined properties. Decide whether this is right.
      properties: Object.fromEntries(Object.entries(_2.propertyInterface).filter(([k, v]) => v !== void 0)),
      childrenWithLayoutProperties,
      childrenWithoutDropZones,
      childKeys
    };
    if (container) {
      props.components = _2.components;
    }
    _2.ref = React2.useRef();
    React2.useEffect(() => {
      designerApi.inDesigner && designerApi.notifyDomNodeChanged(self);
    }, [_2.ref.current]);
    useNotifyMounted(self, isRoot);
    const c = /* @__PURE__ */ React2.createElement(HooksContext.Provider, { value: hooksContext }, /* @__PURE__ */ React2.createElement(DropZoneContext.Provider, { value: dropZoneContext }, /* @__PURE__ */ React2.createElement(WrappedComponent, { ref: _2.ref, ...props })));
    return c;
  };
  const _ReactComponentWrapper = {
    id: componentId++,
    name,
    portalElement: void 0,
    reactComponent: ({ isRoot } = {}) => /* @__PURE__ */ React2.createElement(ErrorBoundary, { self }, /* @__PURE__ */ React2.createElement(InnerComponent, { isRoot })),
    components: [],
    componentLayoutProps: /* @__PURE__ */ new WeakMap(),
    componentVisibility: /* @__PURE__ */ new WeakMap(),
    actions,
    propertyInterface: new Proxy(propertyState, {
      set(target, p, v) {
        target[p] = v;
        _ReactComponentWrapper.forceRender?.();
        return true;
      }
    }),
    dropZones: [],
    sections: /* @__PURE__ */ new Map(),
    sectionInteractions: /* @__PURE__ */ new Map(),
    interactions: /* @__PURE__ */ new Map(),
    inlineEditInteractions: /* @__PURE__ */ new Map(),
    sectionInlineEditInteractions: /* @__PURE__ */ new Map(),
    nextComponentKey: 0,
    componentKeys: /* @__PURE__ */ new WeakMap(),
    nextDesignerStateId: 0
  };
  const _ = _ReactComponentWrapper;
  const addSectionInlineEditInteraction = (sectionId, propName, element, { onStart, onEnd } = {}) => {
    if (!_.sectionInlineEditInteractions.has(sectionId)) {
      _.sectionInlineEditInteractions.set(sectionId, /* @__PURE__ */ new Map());
    }
    _.sectionInlineEditInteractions.get(sectionId).set(propName, {
      type: "whole_component",
      title: `Edit ${propName}`,
      icon: "edit",
      default: true,
      callbacks: {
        execute() {
          onStart?.();
          designerApi.startInlineEditing(self, getStringProp(propName), element, {
            sectionId,
            onFinished: onEnd
          });
        }
      }
    });
  };
  return _;
}
var remapInteractions = (interactions) => interactions.map(
  (i) => i.type === "region" ? { ...i, bounds: i.bounds && "current" in i.bounds ? i.bounds.current : i.bounds } : i
);
function mkComponentClass(spec) {
  const { properties, events, container, layoutProperties } = spec;
  class ReactComponent_ {
    _;
    [IS_REACT] = true;
    constructor() {
      this._ = setupReactComponent(this, spec);
    }
    _anvilNew() {
      subscribeAnvilEvent(this, "x-anvil-page-added", () => {
        if (!this._.portalElement)
          return;
        if (rcStore.getState().components.includes(this))
          return;
        flushSync(() => {
          let parent = getParent(this);
          while (parent) {
            const { components: portalElements, forceRender } = portalElementCache.get(parent) ?? {};
            if (portalElements) {
              if (!portalElements.includes(this)) {
                portalElements.push(this);
                forceRender?.();
              }
              return;
            }
            parent = getParent(parent);
          }
          rcStore.setState(({ components }) => ({ components: [...components, this] }));
        });
      });
      subscribeAnvilEvent(this, "x-anvil-page-removed", () => {
        if (!this._.portalElement)
          return;
        let parent = getParent(this);
        while (parent) {
          const { components: portalElements, forceRender } = portalElementCache.get(parent) ?? {};
          if (portalElements) {
            const idx = portalElements.findIndex((c) => c === this);
            if (idx > 0) {
              portalElements.splice(idx, 1);
              forceRender?.();
              return;
            }
          }
          parent = getParent(parent);
        }
        rcStore.setState(({ components }) => ({
          components: components.filter((x) => x !== this)
        }));
      });
    }
    _anvilSetupDom() {
      const _ = this._;
      if (!(_.portalElement || _.ref?.current)) {
        _.portalElement = document.createElement("div");
        _.portalElement.setAttribute("data-anvil-react-portal", "");
        designerApi.inDesigner && designerApi.notifyDomNodeChanged(this);
      }
      return _.portalElement || _.ref?.current;
    }
    get _anvilDomElement() {
      return this._.portalElement || this._.ref?.current;
    }
    _anvilGetInteractions() {
      return [...remapInteractions([...this._.interactions.values()]), ...this._.inlineEditInteractions.values()];
    }
    _anvilUpdateDesignName(name) {
      this._.designName = name;
      this._.forceRender?.();
    }
    static _anvilEvents = events ?? [];
    static _anvilProperties = (properties ?? []).map((description) => ({ ...description }));
    get reactComponent() {
      return this._.reactComponent({ isRoot: true });
    }
  }
  if (!container)
    return ReactComponent_;
  class ReactContainer extends ReactComponent_ {
    async _anvilAddComponent(component, layoutProperties2) {
      const _ = this._;
      if (!isReactComponent(component)) {
        await component._anvilSetupDom();
        portalElementCache.set(component, { components: [], forceRender: () => _.forceRender?.() });
      } else {
        delete component._.portalElement;
      }
      _.componentKeys.set(component, _.componentKeys.get(component) || _.nextComponentKey++);
      if ("index" in layoutProperties2 && typeof layoutProperties2.index === "number") {
        _.components.splice(layoutProperties2.index, 0, component);
      } else {
        _.components.push(component);
      }
      _.componentLayoutProps.set(component, layoutProperties2);
      _.componentVisibility.set(component, true);
      _.forceRender?.();
      return {
        onRemove: () => {
          this._.components = this._.components.filter((c) => c !== component);
          this._.forceRender?.();
          portalElementCache.delete(component);
        },
        setVisibility: (v) => {
          _.componentVisibility.set(component, v);
          this._.forceRender?.();
        },
        isMounted: false
      };
    }
    _anvilGetComponents() {
      return this._.components;
    }
    _anvilEnableDropMode(droppingObject, flags) {
      const _ = this._;
      if (!container) {
        return [];
      }
      flushSync(() => {
        _.dropping = droppingObject;
        _.forceRender?.();
      });
      const dropLayoutProps = droppingObject.layoutProperties;
      return _.dropZones.filter(({ dropInfo = {} }) => {
        if (dropLayoutProps) {
          for (const [k, v] of Object.entries(dropLayoutProps)) {
            if (dropInfo.layout_properties?.[k] !== v) {
              return false;
            }
          }
        }
        return true;
      });
    }
    _anvilDisableDropMode() {
      this._.dropping = null;
      this._.forceRender?.();
    }
    _anvilGetSections() {
      return Array.from(
        this._.sections.values(),
        ({ id, sectionProperties, sectionPropertyValues, setSectionPropertyValues, ...section }) => ({
          id,
          ...section,
          propertyDescriptions: sectionProperties,
          propertyValues: sectionPropertyValues,
          interactions: [
            ...remapInteractions([...this._.sectionInteractions.get(id)?.values() || []]),
            ...this._.sectionInlineEditInteractions.get(id)?.values() || []
          ]
        })
      );
    }
    _anvilGetSectionDomElement(id) {
      return this._.sections.get(id).element;
    }
    _anvilSetSectionPropertyValues(id, values) {
      const section = this._.sections.get(id);
      return section?.setSectionPropertyValues(values);
    }
    _anvilGetContainerDesignInfo(component) {
      return { layoutPropertyDescriptions: layoutProperties ?? [] };
    }
    _anvilUpdateLayoutProperties(component, updates) {
      const layoutProps = this._.componentLayoutProps.get(component);
      this._.componentLayoutProps.set(component, { ...layoutProps, ...updates });
      flushSync(() => {
        this._.forceRender?.();
      });
    }
  }
  return ReactContainer;
}
function mkComponent(spec) {
  const { properties } = spec;
  const cls = mkComponentClass(spec);
  for (const { name } of properties ?? []) {
    Object.defineProperty(cls.prototype, name, {
      get() {
        return this._.propertyInterface[name];
      },
      set(value) {
        this._.propertyInterface[name] = value;
      },
      configurable: true
    });
  }
  return cls;
}
var reactComponentToSpec = ({
  name,
  properties,
  events,
  layoutProperties,
  container,
  showInToolbox
}) => ({ name, properties, events, layoutProperties, container, showInToolbox });
function registerReactComponent(spec) {
  return registerJsComponent(mkComponent(spec), reactComponentToSpec(spec));
}
var hooks = {
  useActions: () => React2.useContext(HooksContext).useActions(),
  useVisibility: (...args) => React2.useContext(HooksContext).useVisibility(...args),
  useDropping: () => React2.useContext(HooksContext).useDropping(),
  useDesignerApi: () => React2.useContext(HooksContext).useDesignerApi(),
  useComponentState: (...args) => React2.useContext(HooksContext).useComponentState(...args),
  useInteraction: (...args) => React2.useContext(HooksContext).useInteraction(...args),
  useInlineEditRef: (...args) => React2.useContext(HooksContext).useInlineEditRef(...args),
  useInlineEditSectionRef: (...args) => React2.useContext(HooksContext).useInlineEditSectionRef(...args),
  useInlineEditRegionRef: (...args) => React2.useContext(HooksContext).useInlineEditRegionRef(...args),
  useSectionRef: (...args) => React2.useContext(HooksContext).useSectionRef(...args),
  useSectionRefs: (...args) => React2.useContext(HooksContext).useSectionRefs(...args),
  useDesignerInteractionRef: (...args) => React2.useContext(HooksContext).useDesignerInteractionRef(...args),
  useRegionInteractionRef: (...args) => React2.useContext(HooksContext).useRegionInteractionRef(...args)
};
var inDesigner = designerApi.inDesigner;
function includeContext(ctx) {
  rcStore.setState(({ contexts }) => ({ contexts: [...contexts, ctx] }));
}
function useClientConfig(packageName) {
  return getClientConfig(packageName);
}

export {
  registerToolboxSection,
  propertyUtils,
  openForm,
  DropZone,
  registerReactComponent,
  hooks,
  inDesigner,
  includeContext,
  useClientConfig
};
